<template>
  <div class="container container-login">
    <h3 class="text-center">{{ PRODUCT_NAME }}</h3>

    <template v-if="canUseSystem">
      <form autocomplete="off">
        <div v-if="action === 'login'" @keyup.enter="login" class="login col-6 m-auto mt-5">
          <div class="form-group">
            <h5 class="text-danger text-center">{{ loginFail }}</h5>
            <label for="email">
              {{ $t("login.email") }}
            </label>
            <Input
              id="email"
              :error-messages="errors.email"
              :placeholder="$t('login.email')"
              :value="formLogin.email"
              @keyup="onHandleChange('email', formLogin.email)"
              @onHandleChangeValue="(value) => onHandleChange('email', value)"
            />
          </div>
          <div class="form-group">
            <label for="password">
              {{ $t("login.password") }}
            </label>
            <Input
              id="password"
              :placeholder="$t('login.password')"
              :error-messages="errors.password"
              :type="'password'"
              :value="formLogin.password"
              @keyup="onHandleChange('password', formLogin.password)"
              @onHandleChangeValue="(value) => onHandleChange('password', value)"
            />
          </div>
          <div class="d-flex justify-center">
            <v-btn color="primary" large @click="login" style="width: 100%">
              {{ $t("login.login") }}
            </v-btn>
          </div>
          <br />
          <h5 class="text-danger text-center pt-0 mt-0">{{ loginFailUserLock }}</h5>
          <u @click="$router.push({ name: 'resetPassword' })" class="text-link">
            {{ $t("login.forgot_password") }}
          </u>
        </div>
      </form>
      <div v-if="action === 'confirm-code'" @keyup.enter="confirm" class="col-6 m-auto mt-5">
        <p>
          {{ data.email }} {{ $t("login.send_email") }}<br />
          {{ $t("login.require") }}
        </p>
        <div class="form-group">
          <label for="code">{{ $t("login.code") }}</label>
          <Input
            id="code"
            :placeholder="$t('login.code')"
            :error-messages="errorsConfirm.code"
            :type="'text'"
            :value="data.code"
            @keyup="onHandleChangeCode('code', data.code)"
            @onHandleChangeValue="(value) => onHandleChangeCode('code', value)"
          />
        </div>
        <p @click="login" class="text-link">
          {{ $t("login.resend") }}
        </p>
        <div class="d-flex justify-center">
          <Button @click="confirm" class-name="btn btn-primary">
            {{ $t("login.sendCode") }}
          </Button>
        </div>
      </div>
      <div v-if="action === 'change-password'" @keyup.enter="changePassword" class="login col-6 m-auto mt-5">
        <div class="form-group">
          <label for="password_now">{{
            $t("changeFirstTimePassword.password_now")
          }}</label>
          <Input
            id="password_now"
            :placeholder="$t('changeFirstTimePassword.password_now')"
            :error-messages="errorsResetPass.password_now"
            :type="'password'"
            :value="dataChangePass.password_now"
            @keyup="
              onHandleChangeSubmitResetPass(
                'password_now',
                dataChangePass.password_now
              )
            "
            @onHandleChangeValue="
              (value) => onHandleChangeSubmitResetPass('password_now', value)
            "
          />
        </div>
        <div class="form-group">
          <label for="">{{ $t("changeFirstTimePassword.password") }}</label>
          <Input
            id="password"
            :placeholder="$t('changeFirstTimePassword.password')"
            :error-messages="errorsResetPass.password"
            :type="'password'"
            :value="dataChangePass.password"
            @keyup="
              onHandleChangeSubmitResetPass('password', dataChangePass.password)
            "
            @onHandleChangeValue="
              (value) => onHandleChangeSubmitResetPass('password', value)
            "
          />
        </div>
        <div class="form-group">
          <label for="password_confirm">{{
            $t("changeFirstTimePassword.password_confirm")
          }}</label>
          <Input
            id="password_confirm"
            :placeholder="$t('changeFirstTimePassword.password_confirm')"
            :error-messages="errorsResetPass.password_confirm"
            :type="'password'"
            :value="dataChangePass.password_confirm"
            @keyup="
              onHandleChangeSubmitResetPass(
                'password_confirm',
                dataChangePass.password_confirm
              )
            "
            @onHandleChangeValue="
              (value) => onHandleChangeSubmitResetPass('password_confirm', value)
            "
          />
        </div>
        <br />
        <Button
          @click="changePassword"
          class-name="btn btn-primary float-right ml-5"
        >
          {{ $t("resetPassword.resetPass") }}
        </Button>
        <button
          @click="cancelChangePassword"
          type="button"
          class="float-right btn btn-secondary pt-2 pb-3"
        >
          キャンセル
        </button>
      </div>
    </template>
    <template v-else>
      <div class="d-flex">
        <div class="mx-auto" style="margin-top: 30vh;">
        {{ cannotSystemReason }}
        </div>
      </div>
    </template>
    <div v-if="TENANT_NAME" class="tenant-name">{{ TENANT_NAME }}</div>
  </div>
</template>

<script>
/* eslint-disable */
import AuthRepository from "@/services/api/AuthRepository";
import localStorage from "@/common/localStorage";
import { SUCCESS, INTERNAL_SERVER_ERROR } from "../constants/";
import Input from "../components/common/Input";
import Button from "../components/common/Button";
import {
  loginFormSchema,
  confirmCodeFormSchema,
  submitChangePassFormSchema,
} from "../common/schemaValidation";
import { mapMutations } from "vuex";
import {ENVS, PRODUCT_NAME, TOAST_TIME_OUT} from "@/common/constants";
import mixins from "@/mixins";

export default {
  name: "Login",
  mixins: [mixins],
  components: {
    Button,
    Input,
  },
  data() {
    return {
      formLogin: {
        email: "",
        password: "",
      },
      data: {
        email: null,
        code: null,
      },
      user: [],
      loginFail: null,
      loginFailUserLock: null,
      errors: {
        email: "",
        password: "",
      },
      errorsConfirm: {
        code: "",
      },
      dataChangePass: {
        user_id: null,
        password_now: null,
        password: null,
        password_confirm: null,
      },
      errorsResetPass: {
        password_now: null,
        password: null,
        password_confirm: null,
      },
      action: "login",
      TENANT_NAME: ENVS.TENANT_NAME,
      PRODUCT_NAME,

      // システム利用可否
      canUseSystem: true,
      cannotSystemReason: "",
    };
  },
  created() {
    if(window.localStorage.getItem("SHOW_TOAST")) {
      this.$toast.error(window.localStorage.getItem("SHOW_TOAST"))
      window.localStorage.clear()
    }

    // システム利用可否をチェックする
    this.checkCanUseSystem();
  },
  methods: {
    /**
     * (マルチテナント用) テナントの開始日をチェックする
     */
    checkTenantStartDate() {
      // 利用開始日
      const startDate = new Date(ENVS.TENANT_START_DATE);

      // 本日の日付
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // 利用開始日が本日より後の場合は利用不可
      if (today < startDate) {
        return false;
      }
      return true;
    },

    /**
     * システム利用可否をチェックする
     */
    checkCanUseSystem() {
      // テナントの開始日をチェック
      if (!this.checkTenantStartDate()) {
        this.canUseSystem = false;
        this.cannotSystemReason = "本サービスはまだご利用いただけません。";
        return;
      }
    },

    cancelChangePassword() {
      this.action = "login";
    },
    confirm() {
      confirmCodeFormSchema
        .validate(this.data, { abortEarly: false })
        .then(() => {
          this.errorsConfirm = {
			code: ""
          };
          AuthRepository.ConfirmLogin(this.data)
            .then((res) => {
	          if (!res.status || res.status !== SUCCESS) {
		        this.$toast.error("認証コードが一致していません。")
	          }
              if (res.status === SUCCESS) {
                localStorage.saveInfo(JSON.stringify(res.data.data));
                this.setUser(res.data.data);
                this.$toast.success("ログインしました。", {})
                if (screen.width > 760) {
                    this.$router.push({ name: "dashboard" });
                }
                if (screen.width <= 1200) {
                  this.$router.push({ name: "SupportScheduleListMobile" });
                }
              }
            })
            .catch(() => {
              localStorage.destroyInfo();
              this.loginFail = this.$t("login.incorrect_code");
            });
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errorsConfirm[error.path] = error.message;
          });
        });
    },
    login() {
      loginFormSchema
        .validate(this.formLogin, { abortEarly: false })
        .then(() => {
          this.errors = {
			email: "",
	        password: ""
          };
          this.loginFailUserLock = null;
          this.loginFail = null;
          AuthRepository.login(this.formLogin)
            .then((res) => {
              if (!res.status || res.status !== SUCCESS) {
                if(res.error?.accountLock){
                  this.loginFailUserLock = "アカウントがロックされています。管理者にお問い合せください。";
                  return;
                }
                if(res.errorCode === INTERNAL_SERVER_ERROR && !res.error?.code) {
                  this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                  return;
                }
                this.loginFail = this.$t("login.incorrect_account");
                return;
              }
              this.$toast.success("認証コードを再送信しました。", {})
              this.user = res.data.user;
              this.data.email = res.data.user.user_mail;
              this.action =
                res.data.checkCode === true
                  ? "confirm-code"
                  : "change-password";
            })
            .catch(() => {
              this.action = "login";
            });
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
        });
    },
    changePassword() {
      submitChangePassFormSchema
        .validate(this.dataChangePass, { abortEarly: false })
        .then(() => {
          this.errorsResetPass = {
			password_now: "",
	        password: "",
	        password_confirm: ""
          };
          this.dataChangePass.user_id = this.user.id;
          AuthRepository.ChangePassword(this.dataChangePass)
            .then((res) => {
	          if (!res.status || res.status !== SUCCESS) {
		        this.$toast.error("古いパスワードが正しくありません。")
	          }
              if (res.status === SUCCESS) {
                localStorage.saveInfo(JSON.stringify(res.data.data));
                this.setUser(res.data.data);
                this.$toast.success("パスワードを変更しました。", {})
                this.$router.push({ name: "dashboard" });
              }
            })
            .catch(() => {
              localStorage.destroyInfo();
            });
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errorsResetPass[error.path] = error.message;
          });
        });
    },
    onHandleChange(field, value) {
      if (value === undefined || value === null) return;
      this.formLogin[field] = value.trim();
    },
    onHandleChangeCode(field, value) {
      if (value === undefined || value === null) return;
      this.data[field] = value.trim();
    },
    onHandleChangeSubmitResetPass(field, value) {
      this.dataChangePass[field] = value;
    },
    ...mapMutations({
      setUser: "common/setUser",
    }),
  },
};
</script>
<style lang="sass" scoped>
@import "../styles/variable"
.mgt-10
  margin-top: 10px
.text-link
  cursor: pointer
  color: $primary
  text-decoration: underline
.container-login
  margin-top: 10%


  .tenant-name
    position: absolute
    bottom: 30px
    right: 30px
</style>
